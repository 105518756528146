import React from 'react'
import './General.css'

function BackGround({headImage}) {
  return (
    <div className='div_back' style={{ backgroundImage: ' linear-gradient(rgba(53, 37, 37, 0.1), rgba(0, 0, 0, 0.1)), url(' + headImage + ')' }}>
    </div>
  )
}

export default BackGround