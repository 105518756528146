import React, { useState, useEffect } from 'react'
import './NewHotelDetails.css'
import InputRange from 'react-input-range';
import { useDispatch, useSelector } from "react-redux";
import { loadFilterRoom } from '../../Redux/Actions/ActionHotel';

function SearchRoom({ t, id, langLocal }) {
    let dispatch = useDispatch();
    const [children, setchildren] = useState(1)
    const [adults, setadults] = useState(1)
    const [values, setValues] = useState({ min: 20000, max: 1000000 });
    const [loadBtn, setloadBtn] = useState(false)
    const increament = (type) => {
        if (type === 'children') {
            setchildren(parseInt(children) + 1);
        } else if (type === 'adults') {
            setadults(parseInt(adults) + 1);
        }
    };
    const Decreament = (type) => {
        if (type === 'children') {
            setchildren(parseInt(children) - 1);
        } else if (type === 'adults') {
            setadults(parseInt(adults) - 1);
        }
    };
    const handlePriceChange = (newValues) => {
        const clampedValues = {
            min: Math.min(Math.max(newValues.min, 20000), 1000000),
            max: Math.min(Math.max(newValues.max, 20000), 1000000)
        };
        setValues(clampedValues);
    };
    const filterr = event => {
        event.preventDefault();
        const { min, max } = values;
        console.log(min)
        console.log(max)
        console.log(children)
        console.log(adults)
        dispatch(loadFilterRoom(id, children, adults, min, max, langLocal, setloadBtn))
    }
    return (
        <div className='searchroom' >
            <div className='range_price_room' >
                <h4 className='colorWhite' >{t('price')}</h4>
                <InputRange
                    minValue={20000}
                    maxValue={1000000}
                    value={values}
                    onChange={handlePriceChange}
                    className="custom-input-range"
                />
            </div>
            <div className='range_price_room' >
                <div className='childandadults' >
                    <div>
                        <h4 className='colorWhite' >{t('children')}</h4>
                        <div className='div_count' >
                            <span className='pointer ' onClick={() => Decreament('children')} >-</span>
                            <input className='input_count' value={children} onChange={(e)=> setchildren(e.target.value)} />
                            <span className='pointer' onClick={() => increament('children')} >+</span>
                        </div>
                    </div>
                    <div>
                        <h4 className='colorWhite' >{t('adults')}</h4>
                        <div className='div_count' >
                            <span className='pointer ' onClick={() => Decreament('adults')} >-</span>
                            <input  className='input_count'  value={adults} onChange={(e)=> setadults(e.target.value)} />
                            <span className='pointer' onClick={() => increament('adults')} >+</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='range_price_btn' onClick={filterr} >
                {
                    loadBtn ? "Loading ..." : "check"
                }
            </div>
        </div>
    )
}

export default SearchRoom