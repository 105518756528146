import React, { useState } from 'react'

function FilterTables() {
    const [active, setActive] = useState(false)
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    const [active3, setActive3] = useState(false)
    const [active4, setActive4] = useState(false)
    return (
        <div className='filter_tabels' >
            <div className='filter_tabels_top' >
                <i className='fa fa-sort' ></i>
                <h3>FILTER BY </h3>
            </div>
            <div className='filter_tabels_content' >
                <div className='filter_content_one' >
                    <div className='filter_tabels_name' >
                        <span>Name Filter</span>
                        <i className={active ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive(!active)} ></i>
                    </div>
                    {
                        active && (
                            <div className='filter_check_bottom' >
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>Term attribute</span>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>test</span>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>term</span>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>attribute</span>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>test term</span>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>test attribute</span>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='filter_content_one' >
                    <div className='filter_tabels_name' >
                        <span>Name Filter</span>
                        <i className={active1 ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive1(!active1)} ></i>
                    </div>
                    {
                        active1 && (
                            <div className='filter_check_bottom' >
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <div className='check_star_res' >
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                    </div>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <div className='check_star_res' >
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                    </div>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <div className='check_star_res'>
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                        <i className='fa fa-star' ></i>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='filter_content_one' >
                    <div className='filter_tabels_name' >
                        <span>Name Filter</span>
                        <i className={active2 ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive2(!active2)} ></i>
                    </div>
                    {
                        active2 && (
                            <div className='filter_check_bottom' >
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>Term attribute</span>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='filter_content_one' >
                    <div className='filter_tabels_name' >
                        <span>Name Filter</span>
                        <i className={active3 ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive3(!active3)} ></i>
                    </div>
                    {
                        active3 && (
                            <div className='filter_check_bottom' >
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>Term attribute</span>
                                </div>
                                <div className='filter_tables_check' >
                                    <input type='checkbox' />
                                    <span>test</span>
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default FilterTables