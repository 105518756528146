import React, { useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { loadMediaRoom,emptyMediaRoom } from '../../Redux/Actions/ActionHotel';
import ReactLoading from 'react-loading';

function AllMediaRooms({ id, open, setOpen }) {
    let langLocal = localStorage.getItem('langLocal');
    let dispatch = useDispatch();
    const mediaRoom = useSelector(state => state.hotels.mediaRoom)
    const loadingMediaRoom = useSelector(state => state.hotels.loadingMediaRoom)
    const settings = {
        autoPlay: true,
        infiniteLoop: true,
        showStatus: false,
        showThumbs: false
    }
    useEffect(() => {
        dispatch(loadMediaRoom(id,langLocal))
    }, [])
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
                dispatch(emptyMediaRoom())
            }}
            center
            classNames={{
                modal: "pop_up_image",
            }}
            focusTrapped={false}
        >

            {
                loadingMediaRoom == false ? (
                    <div className='pop_images' >
                        <Carousel {...settings} >
                            {
                                mediaRoom.map((imgg) => {
                                    return (
                                        <div key={imgg.id} className='div_imagg' style={{ backgroundImage: ' linear-gradient(rgba(53, 37, 37, 0.3), rgba(0, 0, 0, 0.3)), url(' + imgg.url + ')' }} >
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                ) : <div className='pop_images'><div className='bb' ><ReactLoading type="spinningBubbles" color="#2AD2B6" height={'7%'} width={'7%'} /></div></div>
            }
            {
                (loadingMediaRoom == false && mediaRoom.length == 0) && (
                    <div className='pop_images'><div className='bb' ><span>No Images</span></div></div>
                )
            }
        </RModal>
    )
}

export default AllMediaRooms