import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

function HotelAtt({ hotelDetails, loadingHotelDetails, langLocal }) {
  
  return (
    <>
      <div className={langLocal == "ar" ? "hotel_att_new padding_right" : "hotel_att_new padding_left"} >
        {
          loadingHotelDetails == false ? (
            hotelDetails.attributes_and_terms.map((hh) => {
              return (
                <>
                  <div className='div_att_hotel' >
                    <span className={langLocal == "ar" ? 'att_tit_new border_right' : 'att_tit_new border_left'} >{hh.name}</span>
                    <div className='lineAtt' ></div>
                    {
                      hh.core_terms.map((cc) => {
                        return (
                          <div className='oneatt1' >
                            <i className={langLocal == "ar" ? " fa fa-check margin_left" : " fa fa-check margin_right"} ></i>
                            <span>{cc.name}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                  <br></br>
                </>
              )
            })

          ) : <></>
        }
      </div>
      {/*
        loadingHotelDetails ==false ? (
        hotelDetails.attributes_and_terms.map((att) => {
          return (
            <div dir={langLocal == "ar" ? "rtl" : "ltr"} >
              <br></br><br></br><br></br><br></br>
              <span className='header_title' >{att.name}</span>
              <div className='allAtt'>
                {
                  att.core_terms.map((tt) => {
                    return (
                      <div
                        className={`oneAtt ${activeCard === tt.id ? 'activeAtt' : ''}`}
                        onMouseEnter={() => handleCardHover(tt.id)}
                      >
                        <i className='fas fa-igloo'></i>
                        <h3>{tt.name}</h3>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })):<div className='loadingg' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
      */}
    </>
  );
}

export default HotelAtt;