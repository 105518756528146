import React, { useEffect,useRef } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import BackGround from '../General/BackGround'
import headImage from '../../Images/header3.jpg'
import './AllHotels.css'
import SearchHotel from './SearchHotel'
import FilterHotel from './FilterHotel'
import Application from '../HomePage/Footer/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import OneHotel from './OneHotel'
import { useTranslation } from "react-i18next";
import FilterHotel1 from './FilterHotel1'

function AllHotels() {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
    }, [langLocal])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <NavBar isHome={false} />
            <BackGround headImage={headImage} />
            <div className='GeneralContainer'>
                <SearchHotel />
                <br></br><br></br><br></br><br></br>
                <div dir={langLocal == "ar" ? "rtl" : "ltr"} >
                    <span className='header_title' >{t('allHotels')}</span><br></br>
                    <span className='header_desc' >{t('searchHotel')}</span>
                    <div className='GeneralRow align'  >
                        <FilterHotel1 />
                        <div className='allHotels' >
                            <OneHotel />
                        </div>
                    </div>
                </div>
            </div>
            <Application />
            <Footer />
        </div>
    )
}

export default AllHotels