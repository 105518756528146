import React, { useEffect } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import Application from '../HomePage/Footer/Application/Application';
import Footer from '../HomePage/Footer/Footer';
import './HotelDetails.css'
import './NewHotelDetails.css'
import LocationHotel from './LocationHotel';
import FilterHotel from '../AllHotels/FilterHotel';
import headImage from '../../Images/header3.jpg'
import OneRoom from './OneRoom';
import HotelAtt from './HotelAtt';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { emptyHotelDetails, emptyMediaHotel, loadHotelDetails, loadMediaHotel } from '../../Redux/Actions/ActionHotel';
import ReactLoading from 'react-loading';
import { useTranslation } from "react-i18next";
import BackGround from '../General/BackGround';
import Stars from '../General/Stars';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import SearchRoom from './SearchRoom';
import { useLocation, useNavigate } from 'react-router-dom';


function HotelDetails() {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    let review;
    let rating;
    if (location.state && location.state.revieww>=0 && location.state.ratingg >=0) {
        review = location.state.revieww
        rating = location.state.ratingg
    } else {
        navigate("/not-found")
    }
    let { id } = useParams();
    const hotelDetails = useSelector(state => state.hotels.hotelDetails)
    const loadingHotelDetails = useSelector(state => state.hotels.loadingHotelDetails)
    const mediaHotel = useSelector(state => state.hotels.mediaHotel)
    const loadingMediaHotel = useSelector(state => state.hotels.loadingMediaHotel)
    useEffect(() => {
        dispatch(emptyHotelDetails());
        dispatch(loadHotelDetails(id, langLocal))
        dispatch(loadMediaHotel(id))
        return () => {
            dispatch(emptyHotelDetails());
            dispatch(emptyMediaHotel())
        }
    }, [langLocal])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const settings = {
        autoPlay: true,
        infiniteLoop: true,
        showStatus: false,
        showThumbs: false
    }
    return (
        <div>
            <NavBar isHome={false} />
            {
                hotelDetails.media_urls != undefined ? (
                    <BackGround headImage={hotelDetails.media_urls[0]} />
                ) : <div className='div_back_loading' ></div>
            }

            <div className='GeneralContainer' dir={langLocal == "ar" ? "rtl" : "ltr"}>
                <div className='GeneralRow'  >
                    <div className='hotel_detailsnew' >
                        {
                            loadingHotelDetails == false ? (
                                <>
                                    <div className='allTop' >
                                        <div className='main_info_top' >
                                            <div className='main_info_top_left' >
                                                <div className='main_info_stars' >
                                                    <Stars num={hotelDetails.star_rate} />
                                                </div>
                                                <span className='att_tit' >{hotelDetails.name}</span>
                                                <div className='main_address' >
                                                    <i className='fa fa-map-marker' ></i>
                                                    <span>{hotelDetails.address}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='info_price_top' >
                                            <div className='top_rev margin_right' >
                                                {
                                                    rating === 5 ? (
                                                        <span style={{ fontSize: "20px" }} >{t('Excellent')}</span>
                                                    ) : rating > 5 ? (
                                                        <span style={{ fontSize: "20px" }} >{t('veyexcellent')}</span>
                                                    ) : rating > 3 ? (
                                                        <span style={{ fontSize: "20px" }} >{t('vergood')}</span>
                                                    ) : rating > 0 ? (
                                                        <span style={{ fontSize: "20px" }} >{t('good')}</span>
                                                    ) : (
                                                        <span style={{ fontSize: "20px" }} >{t('maybegood')}</span>
                                                    )
                                                }
                                                <span style={{ color: "rgb(36, 101, 185)" }} >{review} reviews</span>
                                            </div>
                                            <div className='top_rate' >
                                                <span>{rating}/5</span>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div dir='ltr' >
                                        {
                                            loadingMediaHotel === false && mediaHotel.length > 0 ? (
                                                <Carousel {...settings}>
                                                    {mediaHotel.map((mm) => {
                                                        if (mm.url) {
                                                            return (
                                                                <div
                                                                    key={mm.id}
                                                                    className="sliderHotelDetails"
                                                                    style={{
                                                                        background: `linear-gradient(rgba(53, 37, 37, 0.1), rgba(0, 0, 0, 0.1)), url(${mm.url}) center/cover no-repeat`,
                                                                    }}
                                                                ></div>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Carousel>
                                            ) : (
                                                <span>nour</span>
                                            )
                                        }
                                    </div>
                                    <div className='lineDetails' ></div>
                                    <br></br>
                                    <div className='desc_details' >
                                        <span className='att_tit' >{t('description')}</span>
                                        <p>{hotelDetails.content}</p>
                                    </div>
                                </>
                            ) : <div className='div_details_loading' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
                        }

                        <br></br>
                        <div>
                            <span className='att_tit' >{t('avRoom')}</span>
                            <br></br><br></br>
                            <SearchRoom t={t} id={id} langLocal={langLocal} />
                            <OneRoom t={t} langLocal={langLocal} id={id} headImage={headImage} />
                        </div>
                        <div>
                            <span className='att_tit' >{t('rules')}</span>
                            <div className='allrules' >
                                <div className='onerules' >
                                    <span className='onerules1' >Check In</span>
                                    <span className='onerules2'>{hotelDetails.check_in_time}</span>
                                </div>
                                <div className='onerules' >
                                    <span className='onerules1'>Check Out</span>
                                    <span className='onerules2'>{hotelDetails.check_out_time}</span>
                                </div>
                                <div className='onerules' >
                                    <span className='onerules1'>Hotel Policies</span>
                                    <span className='onerules2'>{hotelDetails.policy}</span>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='newmap' >
                            <span className='att_tit' >{t('location')}</span>
                            <div className='div_mapp' >
                                <LocationHotel latt={hotelDetails.map_lat} lngg={hotelDetails.map_lng} />
                            </div>
                        </div>
                    </div>
                    <HotelAtt langLocal={langLocal} hotelDetails={hotelDetails} loadingHotelDetails={loadingHotelDetails} />
                </div>
            </div>
            <Application />
            <Footer />
        </div>
    )
}

export default HotelDetails