import React, { useEffect, useState } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import Application from '../HomePage/Footer/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import LocationHotel from '../HotelDetails/LocationHotel'
import FoodMenu from './FoodMenu'
import './DetailsRestaurant.css'
import FilterTables from './FilterTables'

function DetailsRestaurant() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <NavBar />
            <Header header={false} />
            <div className='GeneralContainer' >
                <div className='GeneralRow align' >
                    <div className='hotel_details' >
                        <h3>Restaurant Name / Restaurant Title</h3>
                        <span>Restaurant address</span>
                        <div className='check_res' >
                            <div>
                                <div className='check' >
                                    <i className='fa fa-clock' ></i>
                                    <span >Check In </span>
                                </div>
                                <span className='hour' >06:54:00 </span>
                            </div>
                            <div>
                                <div className='check' >
                                    <i className='fa fa-clock' ></i>
                                    <span >Check Out </span>
                                </div>
                                <span className='hour' >18:54:00 </span>
                            </div>
                        </div>
                        <div className='FeaturedAndStatus' >
                            <span className='fHotel' >Featured</span>
                            <span className='sHotel' >Open</span>
                        </div>
                        <p>Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? Ad Repellat Voluptates Alias Facere Repudiandae Dolor Accusamus Enim Ut Odit, Aliquam Nesciunt Eaque Nulla Dignissimos.</p>
                    </div>
                    <div className='map_hotel' >
                        <LocationHotel latt={33.510414} lngg={36.278336} />
                    </div>
                </div>
                <br></br><br></br><br></br>
                <span className='header_title' >All Foods</span>
                <span className='header_desc' >Find the right food for your needs</span>
                <FilterTables />
                <br></br><br></br>
                <FoodMenu />
                <br></br><br></br><br></br>
            </div>
            <Application />
            <Footer />
        </div>
    )
}

export default DetailsRestaurant