import React from 'react'
import logo from '../../Images/logo_white1.png'
import './General.css'
import ReactLoading from 'react-loading';

function LoadingPage() {
  return (
    <div className='loadingPage' >
        {/*<img src={logo} />*/}
        <ReactLoading type="spokes" color="#2AD2B6" height={'7%'} width={'7%'} />
    </div>
  )
}

export default LoadingPage