import React, { useState, useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import SinglePagenation from '../Pagination/SinglePagenation';
import { useDispatch, useSelector } from "react-redux";
import { emptyMediaRoom, emptyRoomhotel, loadMediaRoom, loadRoomsHotel } from '../../Redux/Actions/ActionHotel';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import notFound from '../../Images/nooo.png'
import noImg from '../../Images/noImage2.png'
import AllMediaRooms from './AllMediaRooms';
import fac from '../../Images/badge.png'
import RoomAtt from './RoomAtt';

function OneRoom({ id, langLocal, t }) {
    const [pageNumber, setPageNumber] = useState(0);
    const [data, setData] = useState([]);
    let dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [room_id, setRoom_id] = useState(null)
    const [openAtt, setOpenAtt] = useState(false)
    const [room_idAtt, setRoom_idAtt] = useState(null)
    const rooms = useSelector(state => state.hotels.rooms)
    const loadingRooms = useSelector(state => state.hotels.loadingRooms)
    useEffect(() => {
        dispatch(emptyRoomhotel());
        dispatch(loadRoomsHotel(id, langLocal))
        dispatch(loadMediaRoom(id))
        return () => {
            dispatch(emptyRoomhotel());
            dispatch(emptyMediaRoom())
        }
    }, [langLocal])
    useEffect(() => {
        setData(rooms)
    }, [rooms])
    const roomsPerPage = 4;
    const pagesVisited = pageNumber * roomsPerPage;
    const displayrooms = data.slice(
        pagesVisited,
        pagesVisited + roomsPerPage
    );

    const pageCount = Math.ceil(data.length / roomsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const sliderRoom = (id) => {
        setRoom_id(id)
        setOpen(true)
    }
    const AttRoom = (id) => {
        setRoom_idAtt(id)
        setOpenAtt(!openAtt)
    }
    return (
        <>
            {
                loadingRooms == false ? (
                    displayrooms.map((rr) => {
                        return (
                            <>
                                <div className='oneHotel' key={rr.id} >
                                    <div className='carousel_room' >
                                        <i className='fa fa-image' onClick={() => sliderRoom(rr.id)} ></i>
                                        <img className="carousel_image" src={rr.media_urls[0]} />
                                    </div>
                                    <div className='hotel_info' >
                                        <div className='testt' >
                                            <h3>{rr.title}</h3>
                                            <div className='icons_room' >
                                                <div className='one_icons_room' >
                                                    <i className='fa fa-bed' ></i>
                                                    <span>{rr.beds} {t('beds')}</span>
                                                </div>
                                                <div className='one_icons_room'>
                                                    <i className='fa fa-user' ></i>
                                                    <span>{rr.adults} {t('adults')}</span>
                                                </div>
                                                <div className='one_icons_room'>
                                                    <i className='fa fa-child' ></i>
                                                    <span>{rr.children} {t('children')}</span>
                                                </div>
                                            </div>
                                            <div className='para_room'>
                                                <span >{rr.content}</span>
                                            </div>
                                            <div className='div_priceenew' dir={langLocal == "ar" ? "rtl" : "ltr"} >
                                                <span className='tit_price_new'>{t('price')}: </span>
                                                <span>{rr.price} </span>
                                            </div>
                                            <div className='fac_room' onClick={() => AttRoom(rr.id)} >
                                                <img className={langLocal == "ar" ? "marginL_5" : "marginR_5"} src={fac} />
                                                <h4>{t('FACILITIES')}</h4>
                                                <i className={`${langLocal === "ar" ? 'marginR_30' : 'marginL_30'} ${rr.id === room_idAtt ?
                                                    openAtt ?
                                                        'fa fa-chevron-up' : 'fa fa-chevron-down' : "fa fa-chevron-down"}`
                                                }>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    rr.id === room_idAtt ? (
                                        openAtt == true ? (
                                            <RoomAtt id={room_idAtt} langLocal={langLocal} />
                                        ) : <></>
                                    ) : <></>
                                }
                            </>
                        )
                    })) : <div className='loadingg' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
            }
            {
                loadingRooms == false && rooms.length == 0 ? (
                    <div className='notFoundd' >
                        <img src={notFound} />
                        <h3>Not Found</h3>
                    </div>
                ) :
                    <></>
            }
            {
                open == true ? (
                    < AllMediaRooms id={room_id} open={open} setOpen={setOpen} />
                ) : <></>
            }

            <SinglePagenation pageCount={pageCount} changePage={changePage} />
        </>
    )
}

export default OneRoom