import React, { useEffect } from 'react'
import './AllHotels.css'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { emptyBestLocation, loadBestLocation, loadFilterHotel } from '../../Redux/Actions/ActionHotel';
import { useState } from 'react';
import 'react-input-range/lib/css/index.css';
import InputRange from 'react-input-range';
import loc from '../../Images/LOC.png'
import star from '../../Images/star.png'
import price from '../../Images/price.png'
import { loadHotelAtt, loadAllHotel, emptyAllHotels } from '../../Redux/Actions/ActionHotel'


function SearchHotel() {
    let dispatch = useDispatch();
    const bestLoc = useSelector(state => state.hotels.bestLoc)
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    const [star_rate, setstar_rate] = useState('')
    const [location_id, setLocationId] = useState('')
    const [loadBtn, setloadBtn] = useState(false)
    const [values, setValues] = useState({ min: 1000, max: 1000000 });
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
        dispatch(emptyBestLocation())
        dispatch(loadBestLocation(langLocal))
        dispatch(loadHotelAtt(langLocal))
        dispatch(emptyAllHotels())
        const storedLocationId = localStorage.getItem('location');
        if (storedLocationId) {
            console.log("************************")
            setLocationId(storedLocationId)
            console.log(storedLocationId)
            console.log(location_id)
            console.log("************************")
            const { min, max } = values
            dispatch(loadFilterHotel(star_rate, storedLocationId, min, max, langLocal, setloadBtn))
        }
        else {
            dispatch(loadAllHotel(langLocal))
            setLocationId(-1);
        }
    }, [langLocal]);


    const handleLocationChange = (e) => {
        const selectedLocationId = e.target.value;
        setLocationId(selectedLocationId);
    };

    const filterr = event => {
        event.preventDefault();
        const { min, max } = values;
        dispatch(loadFilterHotel(star_rate, location_id, min, max, langLocal, setloadBtn))
    }
    const handlePriceChange = (newValues) => {
        const clampedValues = {
            min: Math.min(Math.max(newValues.min, 1000), 1000000),
            max: Math.min(Math.max(newValues.max, 1000), 1000000)
        };
        setValues(clampedValues);
    };
    return (
        <div className='search_hotel'>
            <div className='one_search' dir={langLocal == "ar" ? "rtl" : "ltr"} >
                <div className='both_search_input' >
                    <img src={loc} className={langLocal == "ar" ? "margin_left" : "margin_right"} />
                    <div>
                        <h4>{t('by_location')}</h4>
                        <select value={location_id} onChange={handleLocationChange} >
                            <option value={-1} disabled="disabled">-- {t('all_locations')} --</option>
                            {
                                bestLoc.map((bb) => {
                                    return (
                                        <option value={bb.id} >{bb.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='one_search' dir={langLocal == "ar" ? "rtl" : "ltr"} >
                <div className='both_search_input' >
                    <img src={star} className={langLocal == "ar" ? "margin_left" : "margin_right"} />
                    <div>
                        <h4>{t('starRate')}</h4>
                        <select onChange={(e) => setstar_rate(e.target.value)} >
                            <option disabled="disabled" selected="selected">-- {t('all_rate')} --</option>
                            <option value="1">1
                            </option>
                            <option value="2">2
                            </option>
                            <option value="3">3
                            </option>
                            <option value="4">4
                            </option>
                            <option value="5">5
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='one_search' dir={langLocal == "ar" ? "rtl" : "ltr"} >
                <div className='both_search_input' >
                    <img src={price} className={langLocal == "ar" ? "margin_left" : "margin_right"} />
                    <div className='range_price' >
                        <h4>{t('price')}</h4>
                        <div dir={"ltr"}>
                            <InputRange
                                minValue={1000}
                                maxValue={1000000}
                                value={values}
                                onChange={handlePriceChange}
                                className="custom-input-range"
                            />
                        </div>

                    </div>
                </div>
            </div>
            <button onClick={filterr} >
                {
                    loadBtn ? "Loading ..." : <>{t('search')}</>
                }
            </button>
        </div>
    )
}

export default SearchHotel