import React from 'react'

function SearchRestaurant() {
    return (
        <div className='search_hotel' >
            <div className='one_search' >
                <h4>Location</h4>
                <select>
                    <option>Syria</option>
                    <option>Egypt</option>
                    <option>Lebanon</option>
                </select>
            </div>
            <div className='one_search' >
                <h4>Check In</h4>
                <input type='time' />
            </div>
            <div className='one_search' >
                <h4>Check Out</h4>
                <input type='time' />
            </div>
            <div className='one_search' >
                <h4>Tables</h4>
                <input type='number' />
            </div>
            <button>Search</button>
        </div>
    )
}

export default SearchRestaurant