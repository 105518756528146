import React, { useEffect } from 'react'
import './Reviews.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pic from '../../../Images/satisfaction.png'
import ReactLoading from 'react-loading';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { settings1 } from '../../General/Notification';
import { emptyAllReviews, loadAllReviews } from '../../../Redux/Actions/ActionHotel';
import Stars from '../../General/Stars';

function Reviews({reviewRef}) {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    let dispatch = useDispatch();
    const Reviews = useSelector(state => state.hotels.Reviews)
    const loadingReviews = useSelector(state => state.hotels.loadingReviews)
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
        dispatch(emptyAllReviews())
        dispatch(loadAllReviews(langLocal))
    }, [langLocal])
    return (
        <div className='GeneralContainerSec ' ref={reviewRef} >
            <span className='header_title' >Best Reviews</span>
            <span className='header_desc' >Most popular Reviews</span>
            {
                loadingReviews == false ? (
                    <Slider {...settings1} className='silder11'  >
                        {
                            Reviews.map((review) => {
                                return (
                                    <div className='revv box_shad' key={review.id} >
                                        <div className='rev_top' >
                                            <div className='rev_stars'>
                                                <Stars num={review.rating} />
                                            </div>
                                            <div className='rev_para' ><p>{review.review}</p></div>
                                        </div>
                                        <div className='rev_bottom' >
                                            <div className='rev_img' >
                                                <img src={pic} />
                                                <div className='pic_info' >
                                                    <span className='pic_info_name11'>{review.author_name}</span>
                                                    <span className='pic_happy'>{review.title}</span>
                                                </div>
                                            </div>
                                            <i className="fas fa-quote-right"></i>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </Slider>
                ) : <div className='loadingg' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
            }

        </div>
    )
}

export default Reviews