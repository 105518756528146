import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './DetailsRestaurant.css'

function FoodMenu() {
    const settings = {
        infinite: false,
        dots: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        responsive: [
            {
                breakpoint: 1084,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true
                }
            },
            {
                breakpoint: 917,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            },

        ]
    };
    return (
        <>
            <div>
                <Slider {...settings} className='silder11' >

                    <div className='oneFood1' >
                        <div className='food_img' ><img src='https://wallpapers.com/images/featured/y48pwsir6u6w5iws.jpg' /></div>
                        <div className='food_info' >
                            <div className='withprice' >
                                <h4>Food Name</h4>
                                <span>230$</span>
                            </div>
                            <div className='starwithtype' >
                                <span className='food_type' >
                                    Food Type
                                </span>
                                <div className='food_star' >
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                </div>
                            </div>
                            <p>Food description Food description Food description</p>
                        </div>
                    </div>
                    <div className='oneFood1' >
                        <div className='food_img' ><img src='https://w0.peakpx.com/wallpaper/625/905/HD-wallpaper-food-meal-meat-rice.jpg' /></div>
                        <div className='food_info' >
                            <div className='withprice' >
                                <h4>Food Name</h4>
                                <span>230$</span>
                            </div>
                            <div className='starwithtype' >
                                <span className='food_type' >
                                    Food Type
                                </span>
                                <div className='food_star' >
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                </div>
                            </div>
                            <p>Food description Food description Food description</p>
                        </div>
                    </div>
                    <div className='oneFood1' >
                        <div className='food_img' ><img src='https://w0.peakpx.com/wallpaper/101/95/HD-wallpaper-burgers-fast-food-delicious-food-sandwiches-harmful-food.jpg' /></div>
                        <div className='food_info' >
                            <div className='withprice' >
                                <h4>Food Name</h4>
                                <span>230$</span>
                            </div>
                            <div className='starwithtype' >
                                <span className='food_type' >
                                    Food Type
                                </span>
                                <div className='food_star' >
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                </div>
                            </div>
                            <p>Food description Food description Food description</p>
                        </div>
                    </div>
                    <div className='oneFood1' >
                        <div className='food_img' ><img src='https://c4.wallpaperflare.com/wallpaper/930/403/744/bow-meat-sauce-steak-wallpaper-preview.jpg' /></div>
                        <div className='food_info' >
                            <div className='withprice' >
                                <h4>Food Name</h4>
                                <span>230$</span>
                            </div>
                            <div className='starwithtype' >
                                <span className='food_type' >
                                    Food Type
                                </span>
                                <div className='food_star' >
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                </div>
                            </div>
                            <p>Food description Food description Food description</p>
                        </div>
                    </div>
                    <div className='oneFood1' >
                        <div className='food_img' ><img src='https://wallpapers.com/images/featured/y48pwsir6u6w5iws.jpg' /></div>
                        <div className='food_info' >
                            <div className='withprice' >
                                <h4>Food Name</h4>
                                <span>230$</span>
                            </div>
                            <div className='starwithtype' >
                                <span className='food_type' >
                                    Food Type
                                </span>
                                <div className='food_star' >
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                </div>
                            </div>
                            <p>Food description Food description Food description</p>
                        </div>
                    </div>
                    <div className='oneFood1' >
                        <div className='food_img' ><img src='https://wallpapers.com/images/featured/y48pwsir6u6w5iws.jpg' /></div>
                        <div className='food_info' >
                            <div className='withprice' >
                                <h4>Food Name</h4>
                                <span>230$</span>
                            </div>
                            <div className='starwithtype' >
                                <span className='food_type' >
                                    Food Type
                                </span>
                                <div className='food_star' >
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                    <i className='fa fa-star' ></i>
                                </div>
                            </div>
                            <p>Food description Food description Food description</p>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default FoodMenu