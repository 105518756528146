import React,{useRef} from 'react'
import img1 from '../../../Images/a1.jpg'
import img2 from '../../../Images/a2.jpg'
import marker from '../../../Images/marker.png'
import './AboutUs.css'

function AboutUs({aboutRef}) {
    return (
        <div className='GeneralContainer' ref={aboutRef} >
            <section className="aboutt top" id="about">
                <div className="GeneralRow">
                    <div className="left">
                        <div className="img">
                            <img src={img1} alt="" className="image1" />
                            <img src={img2} alt="" className="image2" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="heading">
                            <h5>RAISING COMFOMRT TO THE HIGHEST LEVEL</h5>
                            <div className='mark' >
                                <img src={marker} />
                                <h2>Welcome to check-in website</h2>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutUs