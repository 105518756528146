import * as types from "../ActionType";
import axios from "axios"; 
import { base_url } from "../../Components/General/Notification";
let token = ""

const getFeaturedHotel = (featuredHotels) => {
    return ({
        type: types.GET_FEATURED_HOTEL,
        payload: featuredHotels
    })
}

const getAllHotel = (allHotels) => {
    return ({
        type: types.GET_ALL_HOTELS,
        payload: allHotels
    })
}

const getRoomsHotel = (rooms) => {
    return ({
        type: types.ROOMS_BY_HOTEL,
        payload: rooms
    })
}

const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}

const getHotelDetails = (hotelDetails) => {
    return ({
        type: types.HOTER_DETAILS,
        payload: hotelDetails
    })
}

const getMediaHotel = (mediaHotel) => {
    return ({
        type: types.GET_MEDIA_HOTEL,
        payload: mediaHotel
    })
}

const getMediaRoom = (mediaRoom) => {
    return ({
        type: types.GET_MEDIA_ROOM,
        payload: mediaRoom
    })
}

const getBestLocation = (bestLoc) => {
    return ({
        type: types.GET_BEST_LOCATION,
        payload: bestLoc
    })
}

const getAllReviews = (Reviews) => {
    return ({
        type: types.GET_ALL_REVIEWS,
        payload: Reviews
    })
}

const filterHotels = (allHotels) => {
    return ({
        type: types.FILTER_HOTEL,
        payload: allHotels
    })
}

const filterRooms = (rooms) => {
    return ({
        type: types.FILTER_ROOM,
        payload: rooms
    })
}

const getRoomAtt = (roomAtt) => {
    return ({
        type: types.GET_ROOM_ATTRIBUTE,
        payload: roomAtt
    })
}

const getHotelAtt = (hotelAtt) => {
    return ({
        type: types.GET_HOTLES_ATTRIBUTES,
        payload: hotelAtt
    })
}

export const loadFeaturedHotel = (lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/hotels/featured`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getFeaturedHotel(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const loadAllHotel = (lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/hotels/all`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getAllHotel(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const loadRoomsHotel = (id,lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/hotels/${id}/rooms`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getRoomsHotel(res.data.list))
        }).catch((err) => console.log(err))
    }
}
export const emptyRoomhotel = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_ROOMS_HOTEL ) )
    }
}
export const loadHotelDetails = (id,lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/hotels/${id}/details`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getHotelDetails(res.data.list))
        }).catch((err) => console.log(err))
    }
}
export const emptyHotelDetails = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_HOTER_DETAILS ) )
    }
}

export const loadMediaHotel = (id) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/hotels/${id}/media`,{
            headers:{
                "Authorization": token
            }
        }).then((res) => {
            console.log(res);
            dispatch(getMediaHotel(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const loadMediaRoom = (id) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/rooms/${id}/media`,{
            headers:{
                "Authorization": token
            }
        }).then((res) => {
            console.log(res);
            dispatch(getMediaRoom(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const emptyMediaHotel = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_MEDIA_HOTEL ) )
    }
}

export const emptyMediaRoom = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_MEDIA_ROOM ) )
    }
}

export const emptyFeaturedHotel = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_FEATURED_HOTEL ) )
    }
}


export const loadBestLocation = (lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/cities/best-location`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getBestLocation(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const emptyBestLocation = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_BEST_LOCATION ) )
    }
}

export const emptyAllHotels = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_ALL_HOTELS ) )
    }
}

export const loadAllReviews = (lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/review/hotel/best`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getAllReviews(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const emptyAllReviews = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_ALL_REVIEWS ) )
    }
}

export const loadFilterHotel = (star_rate,location_id,min_price,max_price,lang,setloadBtn) => {
    return function (dispatch) {
        setloadBtn(true)
        axios.get(`${base_url}/api/v1/customer/hotels/all?filter[star_rate]=${star_rate}&filter[location_id]=${location_id}&filter[min_price]=${min_price}&filter[max_price]=${max_price}`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            setloadBtn(false)
            dispatch(filterHotels(res.data.list))

        }).catch((err) => {
            console.log(err)
            setloadBtn(false)
        })
    }
}

export const loadFilterRoom = (hotel_id,children,adults,min_price,max_price,lang,setloadBtn) => {
    return function (dispatch) {
        setloadBtn(true)
        axios.get(`${base_url}/api/v1/customer/hotels/${hotel_id}/rooms?filter[min_price]=${min_price}&filter[max_price]=${max_price}&filter[adults]=${adults}&filter[children]=${children}`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            setloadBtn(false)
            dispatch(filterRooms(res.data.list))
        }).catch((err) => {
            console.log(err)
            setloadBtn(false)
        })
    }
}

export const loadRoomAtt = (id,lang) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/rooms/${id}/details`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getRoomAtt(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const emptyRoomAtt = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_ROOM_ATTRIBUTE ) )
    }
}

export const loadHotelAtt = (lang) => {
    console.log("______________________________")
    console.log(lang)
    console.log("______________________________")

    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/attributes/terms?filter[service]=hotel`,{
            headers:{
                "Authorization": token,
                "lang": lang
            }
        }).then((res) => {
            console.log(res);
            dispatch(getHotelAtt(res.data.list))
        }).catch((err) => console.log(err))
    }
}

export const emptyHotelAtt = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch( emptyGeneral(emptyy , types.EMPTY_HOTLES_ATTRIBUTES ) )
    }
}