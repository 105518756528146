import * as types from "../ActionType";
import axios from "axios";
import { base_url } from "../../Components/General/Notification";
//import { hasRout } from "../../Components/GeneralContent/useRout";

const getStatic = (Static) => {
    return ({
        type: types.GET_STATIC,
        payload: Static
    })
}



export const loadStatic = (lang,navigate) => {
    return function (dispatch) {
        axios.get(`${base_url}/api/v1/customer/config/control-panel-statistics`).then((res) => {
            console.log(res);
            dispatch(getStatic(res.data.list));
        }).catch((err) => {
            console.log(err)
            //notifyError(err.response.data.message)
            //hasRout(err.response.status,navigate)
        })
    }
}
