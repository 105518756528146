import React, { useEffect } from 'react'
import './NavBar.css'
import logo from '../../../Images/GOCHECK-in1.png'
import TopBar from './TopBar/TopBar'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

function NavBar({ aboutRef, locationRef, reviewRef, isHome }) {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
    }, [langLocal]);
    const scrollIntoView = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className='fixed'>
            <TopBar />
            <nav className="navbar" dir={langLocal == "ar" ? "ltr" : "rtl"} >
                <div className="logo">
                    <img src={logo} />
                </div>

                <ul className="nav-links">

                    <input type="checkbox" id="checkbox_toggle" />

                    <label htmlFor="checkbox_toggle" className="hamburger">&#9776;</label>
                    <div className="&rdquo;menu&rdquo;"></div>

                    <div className="menu" dir={langLocal == "ar" ? "rtl" : "ltr"}>
                        {
                            isHome ? (
                                <>
                                    <li><Link to="/" >{t('home')}</Link></li>
                                    <li><Link onClick={() => scrollIntoView(aboutRef)} >{t('about')}</Link></li>
                                    <li><Link onClick={() => scrollIntoView(locationRef)} >{t('locations')}</Link></li>
                                    <li><Link onClick={() => scrollIntoView(reviewRef)} >{t('reviews')}</Link></li>
                                    <li onClick={() => localStorage.removeItem('location')}><Link to="/hotels">{t("hotels")}</Link></li></>
                            ) : (
                                <>
                                    <li><Link to="/" >{t('home')}</Link></li>
                                    <li><Link to="/" >{t('about')}</Link></li>
                                    <li><Link to="/" >{t('locations')}</Link></li>
                                    <li><Link to="/" >{t('reviews')}</Link></li>
                                    <li><Link to="/hotels">{t("hotels")}</Link></li></>
                            )
                        }

                    </div>

                </ul>

            </nav>
        </div>
    )
}

export default NavBar