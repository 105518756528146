import React from 'react'
import notfound from '../../Images/404.svg'
import './NotFound.css'
import { useNavigate } from 'react-router-dom';

function NotFound() {
    const history = useNavigate();
    function handleClick() {
        history("/")
    }
  return (
    <div className='div_notfound' >
        <img src={notfound} />
        <div className='notfound_info' >
            <span className='num404' >404</span>
            <span className='page' >Page Not Found</span>
            <button onClick={handleClick} >Back</button>
        </div>
    </div>
  )
}

export default NotFound