import React, { useEffect,useRef } from 'react'
import NavBar from './NavBar/NavBar'
import Header from './Header/Header'
import AboutUs from './AboutUs/AboutUs'
import Bestseller from './Bestseller/Bestseller'
import Top_Destinations from './TopDestinations/Top_Destinations'
import Reviews from './Reviews/Reviews'
import Application from './Footer/Application/Application'
import Footer from './Footer/Footer'
import Best_Tours from './Best_Tours/Best_Tours'
import Services from './Services/Services'
import Counter from './Counter/Counter'
import he1 from '../../Images/header1.jpg'
import he2 from '../../Images/header2.jpg'
import he3 from '../../Images/header3.jpg'

function Home() {
  const aboutRef = useRef(null);
  const locationRef = useRef(null);
  const reviewRef = useRef(null);
  let images = [
    {
      id: 1,
      url: he1
    },
    {
      id: 2,
      url: he2
    },
    {
      id: 3,
      url: he3
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <NavBar isHome={true} aboutRef={aboutRef} locationRef={locationRef} reviewRef={reviewRef} />
      <Header header={true} arr={images} loadingArr={false} />
      <AboutUs aboutRef={aboutRef} />
      {/*<Services />*/}
      <Bestseller />
      {/*<Best_Tours />*/}
      <Counter />
      <br></br><br></br><br></br><br></br><br></br>
      <Top_Destinations locationRef={locationRef} />
      <Reviews reviewRef={reviewRef} />
      <Application />
      <Footer />
    </div>
  )
}

export default Home