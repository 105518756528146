import React, { useState, useEffect } from 'react'
import './NewHotelDetails.css'
import { useDispatch, useSelector } from "react-redux";
import { emptyRoomAtt, loadRoomAtt } from '../../Redux/Actions/ActionHotel';
import ReactLoading from 'react-loading';

function RoomAtt({ id, langLocal }) {
    let dispatch = useDispatch();
    const roomAtt = useSelector(state => state.hotels.roomAtt)
    const loadingRoomAtt = useSelector(state => state.hotels.loadingRoomAtt)
    useEffect(() => {
        dispatch(loadRoomAtt(id,langLocal))
        return () => {
            dispatch(emptyRoomAtt());
        }
    },[])
    return (
        <div className='roomatt' >
            {
                loadingRoomAtt ==false ? (
                roomAtt.attributes_and_terms != undefined ? (
                    roomAtt.attributes_and_terms.map((rr) => {
                        return (
                            <div>
                                <h3>{rr.name}</h3>
                                <div className='allroomatt' >
                                    {
                                        rr.core_terms != undefined ? (
                                            rr.core_terms.map((aa) => {
                                                return (
                                                    <div className='oneroomatt' >
                                                        <i className={langLocal == "ar" ? "fa fa-car margin_left" : "fa fa-car margin_right"} ></i>
                                                        <span>{aa.name}</span>
                                                    </div>
                                                )
                                            })
                                        ) : <></>
                                    }
                                </div>
                            </div>
                        )
                    })
                ) : <></>): <div className='div_roomatt_loading' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
            }
            {/*<div>
                <h3>FACILITIES</h3>
                <div className='allroomatt' >
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? "fa fa-car margin_left" : "fa fa-car margin_right"} ></i>
                        <span>Car hire</span>
                    </div>
                    <div className='oneroomatt' >
                        <div className='oneatt1' >
                            <i className={langLocal == "ar" ? " fa fa-coffee margin_left" : " fa fa-coffee margin_right"} ></i>
                            <span>Coffee and tea</span>
                        </div>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-wifi margin_left" : " fa fa-wifi margin_right"}  ></i>
                        <span> Internet – Wifi</span>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-tv margin_left" : " fa fa-tv margin_right"}  ></i>
                        <span> Flat Tv</span>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-bicycle margin_left" : " fa fa-bicycle margin_right"} ></i>
                        <span> Bicycle hire</span>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-clock margin_left" : " fa fa-clock margin_right"}></i>
                        <span> Wake-up call</span>
                    </div>
                </div>
            </div>
            <div>
                <h3>FACILITIES</h3>
                <div className='allroomatt' >
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? "fa fa-car margin_left" : "fa fa-car margin_right"} ></i>
                        <span>Car hire</span>
                    </div>
                    <div className='oneroomatt' >
                        <div className='oneatt1' >
                            <i className={langLocal == "ar" ? " fa fa-coffee margin_left" : " fa fa-coffee margin_right"} ></i>
                            <span>Coffee and tea</span>
                        </div>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-wifi margin_left" : " fa fa-wifi margin_right"}  ></i>
                        <span> Internet – Wifi</span>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-tv margin_left" : " fa fa-tv margin_right"}  ></i>
                        <span> Flat Tv</span>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-bicycle margin_left" : " fa fa-bicycle margin_right"} ></i>
                        <span> Bicycle hire</span>
                    </div>
                    <div className='oneroomatt' >
                        <i className={langLocal == "ar" ? " fa fa-clock margin_left" : " fa fa-clock margin_right"}></i>
                        <span> Wake-up call</span>
                    </div>
                </div>
            </div>*/}
        </div>
    )
}

export default RoomAtt