import React from 'react'
import GoogleMapReact from "google-map-react";

function LocationHotel({ latt, lngg }) {
    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0" }}
                center={{ lat: latt, lng: lngg }}
                defaultZoom={13}
            >
            </GoogleMapReact>
        </>
    )
}

export default LocationHotel