import React, { useState, useEffect } from 'react'
import SinglePagenation from '../Pagination/SinglePagenation'
import { useDispatch, useSelector } from "react-redux";
import { loadAllHotel, emptyAllHotels } from '../../Redux/Actions/ActionHotel';
import Stars from '../General/Stars';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import medal from '../../Images/badge.png'
import paper from '../../Images/paper-plane.png'

function OneHotel() {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    let navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    let dispatch = useDispatch();
    const allHotels = useSelector(state => state.hotels.allHotels)
    const loadingAllHotels = useSelector(state => state.hotels.loadingAllHotels)
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
        
    }, [langLocal])
    const oneHotelPerPage = 4;
    const pagesVisited = pageNumber * oneHotelPerPage;
    const displayoneHotel = allHotels.slice(
        pagesVisited,
        pagesVisited + oneHotelPerPage
    );

    const pageCount = Math.ceil(allHotels.length / oneHotelPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const RoutToHotelDetails = (idd,review,rating) => {
        navigate(`/hotel/${idd}`, { state: { id: idd, revieww: review, ratingg: rating } })
    }
    return (
        <>
            {
                loadingAllHotels == false ? (
                    displayoneHotel.map((oo) => {
                        return (
                            <div className='oneHotel' key={oo.id} onClick={() => RoutToHotelDetails(oo.id , oo.numberOfReviews,oo.numberOfRatings)} >
                                <div className='fea_hotel' >
                                    {
                                        oo.is_featured ? (
                                            <span>Featured</span>
                                        ) : <span>Not Featured</span>
                                    }
                                </div>
                                <img src={oo.media_urls[0]} className={langLocal == 'ar' ? "border_ar" : "border_en"} />
                                <div className='hotel_info' >
                                    <div className='testt' >
                                        <div className='star_group_hotel' >
                                            <Stars num={oo.star_rate} />
                                        </div>
                                        <h3>{oo.name}</h3>
                                        <span className="tit_testt" >{oo.title}</span>
                                        <div className='div_icon_allhotel' >
                                            <i className='fa fa-envelope' ></i>
                                            <span>{oo.email}</span>
                                        </div>
                                        <div className='div_icon_allhotel'>
                                            <i className='fa fa-phone' ></i>
                                            <span>{oo.phone}</span>
                                        </div>
                                        <div className='div_icon_allhotel'>
                                            <i className='fa fa-thumbs-up' ></i>
                                            <span>{oo.likes}</span>
                                        </div>
                                        <div className='newplace' >
                                            <img src={paper} />
                                            <span>{oo.address}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='linee' ></div>
                                <div className='hotel_info_price' dir={langLocal == "ar" ? "rtl" : "ltr"} >
                                    <div className='info_price_top' dir={langLocal == "ar" ? "rtl" : "ltr"} >
                                        <div className='top_rev' >
                                            {
                                                oo.numberOfRatings === 5 ? (
                                                    <span>{t('Excellent')}</span>
                                                ) : oo.numberOfRatings > 5 ? (
                                                    <span>{t('veyexcellent')}</span>
                                                ) : oo.numberOfRatings > 3 ? (
                                                    <span>{t('vergood')}</span>
                                                ) : oo.numberOfRatings > 0 ? (
                                                    <span>{t('good')}</span>
                                                ) : (
                                                    <span>{t('maybegood')}</span>
                                                )
                                            }
                                            <span style={{ color: "rgb(36, 101, 185)" }} >{oo.numberOfReviews} {t('reviews')}</span>
                                        </div>
                                        <div className='top_rate' >
                                            <span>{oo.numberOfRatings}/5</span>
                                        </div>
                                    </div>
                                    <div className='info_price_bottom'>
                                        <span  >{t('from')} {oo.min_price}s.p {t('to')} {oo.max_price}s.p /{t('night')}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })) : <div className='loadingg' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
            }
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
        </>
    )
}

export default OneHotel