import {combineReducers} from "redux"
import CategoryReducer from "./CategoryReducer"
import HotelsReducer from "./HotelsReducer"
import StaticReducer from './StaticReducer'


export default combineReducers({
    category : CategoryReducer,
    hotels: HotelsReducer,
    staticCount: StaticReducer
})