import React from 'react'
import './Services.css'

function Services() {
    return (
        <section className="timer about wrapper">
            <div className="background"> </div>
            <div className="container">
                <div className="heading">
                    <h5>LAST MINUTE!</h5>
                    <h2> <span>Incredible!</span> Are you coming today</h2>
                </div>
            </div>
        </section>
    )
}

export default Services