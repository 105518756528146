import React, { useEffect } from 'react'
import './Bestseller.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { emptyFeaturedHotel, loadFeaturedHotel } from '../../../Redux/Actions/ActionHotel';
import Stars from '../../General/Stars'
import ReactLoading from 'react-loading';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from '../../General/Notification';
import { useTranslation } from "react-i18next";

function Bestseller() {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    let dispatch = useDispatch();
    const featuredHotels = useSelector(state => state.hotels.featuredHotels)
    const loadingFeaturedHotel = useSelector(state => state.hotels.loadingFeaturedHotel)
    let navigate = useNavigate();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
        dispatch(emptyFeaturedHotel())
        dispatch(loadFeaturedHotel(langLocal))
    }, [langLocal])
    const RoutToAllHotels = () => {
        navigate("/AllHotels")
    }
    const RoutToHotelDetails = (idd,review,rating) => {
        navigate(`/hotel/${idd}`, { state: { revieww: review, ratingg: rating } })
    }
    return (
        <div className='GeneralContainerSec' dir={langLocal == "ar" ? "rtl" : "ltr"} >
            <span className='header_title' >{t('bestHotels')}</span>
            <span className='header_desc' >{t('bestHotelsSubTitle')}</span>
            <br></br>
            {
                loadingFeaturedHotel == false ? (
                    <Slider {...settings} className='silder11' >
                        {
                            featuredHotels.map((bb) => {
                                return (
                                    <div key={bb.id} className='Bestseller_card' dir={langLocal == "ar" ? "rtl" : "ltr"} onClick={() => RoutToHotelDetails(bb.id, bb.numberOfReviews,bb.numberOfRatings)} >
                                        <div className='rate_stars' >
                                            <Stars num={bb.star_rate} />
                                        </div>
                                        <div className='Bestseller_img' >
                                            <img src={bb.media_urls[0]} />
                                        </div>
                                        <div className='Bestseller_info' >
                                            <div className='checkF' >
                                                <span className='Bestseller_info_title' >{bb.name}</span>
                                            </div>
                                            <div className='div_address' >
                                                <i className='fa fa-map-marker mark' ></i>
                                                <span className='Bestseller_info_place' >{bb.address}</span>
                                            </div>
                                            <div className='rate_review'>
                                                {
                                                    bb.numberOfRatings === 5 ? (
                                                        <span>{bb.numberOfRatings}/5 Excellent</span>
                                                    ) : bb.numberOfRatings > 5 ? (
                                                        <span>{bb.numberOfRatings}/5 Very Excellent</span>
                                                    ) : bb.numberOfRatings > 3 ? (
                                                        <span>{bb.numberOfRatings}/5 Very Good</span>
                                                    ) : bb.numberOfRatings > 0 ? (
                                                        <span>{bb.numberOfRatings}/5 Good</span>
                                                    ) : (
                                                        <span>{bb.numberOfRatings}/5 Maybe Good</span>
                                                    )
                                                }
                                                <i className='fa fa-circle'></i>
                                                <span>{bb.numberOfReviews} Reviews</span>
                                            </div>
                                            <span className='pricing' >from {bb.min_price}s.p to {bb.max_price}s.p /night</span>
                                            {/*<div className='rate_review' >
                                                <div>
                                                    <div className='check' >
                                                        <i className='fa fa-clock-o' ></i>
                                                        <span className='excellent' >Check In </span>
                                                    </div>
                                                    <span className='hour' >{bb.check_in_time}</span>
                                                </div>
                                                <div>
                                                    <div className='check' >
                                                        <i className='fa fa-clock-o' ></i>
                                                        <span className='excellent' >Check Out </span>
                                                    </div>
                                                    <span className='hour' >{bb.check_out_time}</span>
                                                </div>
                                            </div>*/}
                                            {/*div className='details_btn'><button onClick={() => RoutToHotelDetails(bb.id)} >{t('details')}</button></div>*/}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                ) : <div className='loadingg' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
            }
            <div className='allItem'><button onClick={() => RoutToAllHotels()} >{t('allHotels')}</button></div>
        </div>
    )
}

export default Bestseller

/**/