import * as types from "../ActionType";

const initState = {
    categories: [],
    loading : true
}

const CategoryReducer = (state = initState , action) => {
    switch(action.type){
        case types.GET_ALL_CATEGORY:
            return {...state , categories: action.payload , loading: false}
        default:
            return state;
    }
};

export default CategoryReducer;
