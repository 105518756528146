import React, { useEffect } from "react";
import "./Application.css";
import app from "../../../../Images/app2.png";
import google_play from "../../../../Images/google_play.svg";
import app_store from "../../../../Images/app_store.png";

import { Link } from "react-router-dom";

export default function Application() {
  return (

    <div className="application">
      <div className="inner_app">
        <div className="application_left">
          <img src={app} alt="clickgo Application" title="GOCHECK-in Application" />
        </div>
        <div className="application_right">
          <h2>
            Get easy access of all features using check in Application
          </h2>
          <div style={{display:"flex", columnGap: "10px"}}>
            <img src={google_play} />
            <img src={app_store} style={ {width:"190px",height: "57px"} }/>
          </div>
        </div>

      </div>
    </div>

  );
}
