import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loadHotelAtt } from '../../Redux/Actions/ActionHotel';
import ReactLoading from 'react-loading';

function FilterHotel1() {
  let langLocal = localStorage.getItem('langLocal');
  let dispatch = useDispatch();
  const hotelAtt = useSelector(state => state.hotels.hotelAtt)
  const loadingHotelAtt = useSelector(state => state.hotels.loadingHotelAtt)
  useEffect(() => {
    dispatch(loadHotelAtt(langLocal))
  }, [])
  
  const [t, i18n] = useTranslation();
  const [activeItems, setActiveItems] = useState([]); // مصفوفة لتخزين الحالة النشطة لكل one_filter

  const handleToggle = (index) => {
    const updatedItems = [...activeItems];
    updatedItems[index] = !updatedItems[index];
    setActiveItems(updatedItems);
  };
  return (
    <div className='filter'>
      <div className='head_filter'>
        <i className='fa fa-sort'></i>
        <h4>{t('filter_by')}</h4>
      </div>
      {
        loadingHotelAtt == false ? (
          hotelAtt.map((dd, index) => (
            <div className='one_filter' key={dd.id}>
              <div className='one_filter_head'>
                <span>{dd.name}</span>
                <i
                  className={activeItems[index] ? 'fas fa-angle-up' : 'fas fa-angle-down'}
                  onClick={() => handleToggle(index)}
                ></i>
              </div>
              {activeItems[index] && (
                <>
                  {dd.core_terms.map((tt) => (
                    <div className='filter_star' key={tt.id}>
                      <input type='checkbox' />
                      <span>{tt.name}</span>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))
        ) : <div className='loadingAtt' > <ReactLoading type="cylon" color="#2AD2B6" height={'15%'} width={'15%'} /> </div>
      }
      <button>{t('search')}</button>
    </div>
    
  );
}

export default FilterHotel1;