import React, { useState } from 'react'
import img1 from '../../Images/res1.jpg'
import img2 from '../../Images/res2.jpg'
import img3 from '../../Images/res3.jpg'
import SinglePagenation from '../Pagination/SinglePagenation'

function OneRestaurant() {
    const [pageNumber, setPageNumber] = useState(0);
    const oneRes = [
        {
            id: 1,
            image: img1,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: false
        },
        {
            id: 2,
            image: img2,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: true
        },
        {
            id: 3,
            image: img3,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: false
        },
        {
            id: 4,
            image: img1,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: true
        },
        {
            id: 5,
            image: img2,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: false
        },
        {
            id: 6,
            image: img3,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: true
        },
        {
            id: 7,
            image: img1,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: false
        },
        {
            id: 8,
            image: img2,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: true
        },
        {
            id: 9,
            image: img3,
            name: "Restaurant Name",
            description: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores Laboriosam Praesentium Enim Maiores? ",
            place: "Damascus",
            open: false
        },
    ]
    const oneResPerPage = 4;
    const pagesVisited = pageNumber * oneResPerPage;
    const displayoneRes = oneRes.slice(
        pagesVisited,
        pagesVisited + oneResPerPage
    );

    const pageCount = Math.ceil(oneRes.length / oneResPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    return (
        <>
            {
                displayoneRes.map((oo) => {
                    return (
                        <>
                            <div className='oneRes' >
                                <img src={oo.image} />
                                <div className='res_info' >
                                    <div className='res_info_top' >
                                        <h3>{oo.name}</h3>
                                        <div className='res_star' >
                                            <i className='fa fa-star' ></i>
                                            <i className='fa fa-star' ></i>
                                            <i className='fa fa-star' ></i>
                                            <i className='fa fa-star' ></i>
                                        </div>
                                    </div>
                                    <div className='res_address' >
                                        <i className='fa fa-map-marker' ></i>
                                        <span>{oo.place}</span>
                                    </div>
                                    <div>
                                        <p>{oo.description}</p>
                                    </div>
                                    <div className='open_res' >
                                        <i className='fa fa-circle' ></i>
                                        <span>
                                            {
                                                oo.open? "Open" : "Close"
                                            }
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <br></br><br></br>
                        </>
                    )
                })
            }
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
        </>
    )
}

export default OneRestaurant