export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_FEATURED_HOTEL = "GET_FEATURED_HOTEL";
export const EMPTY_FEATURED_HOTEL = "EMPTY_FEATURED_HOTEL";
export const GET_ALL_HOTELS = "GET_ALL_HOTELS";
export const EMPTY_ALL_HOTELS = "EMPTY_ALL_HOTELS";
export const ROOMS_BY_HOTEL = "ROOMS_BY_HOTEL";
export const EMPTY_ROOMS_HOTEL = "EMPTY_ROOMS_HOTEL";
export const HOTER_DETAILS = "HOTER_DETAILS";
export const EMPTY_HOTER_DETAILS = "EMPTY_HOTER_DETAILS";
export const GET_MEDIA_HOTEL = "GET_MEDIA_HOTEL";
export const EMPTY_MEDIA_HOTEL = "EMPTY_MEDIA_HOTEL";
export const GET_MEDIA_ROOM = "GET_MEDIA_ROOM";
export const EMPTY_MEDIA_ROOM = "EMPTY_MEDIA_ROOM";
export const GET_BEST_LOCATION = "GET_BEST_LOCATION";
export const EMPTY_BEST_LOCATION = "EMPTY_BEST_LOCATION";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const EMPTY_ALL_REVIEWS = "EMPTY_ALL_REVIEWS";
export const FILTER_HOTEL = "FILTER_HOTEL";
export const FILTER_ROOM = "FILTER_ROOM";
export const GET_ROOM_ATTRIBUTE = "GET_ROOM_ATTRIBUTE";
export const EMPTY_ROOM_ATTRIBUTE = "EMPTY_ROOM_ATTRIBUTE";
export const GET_STATIC = "GET_STATIC";

export const GET_HOTLES_ATTRIBUTES = "GET_HOTLES_ATTRIBUTES";
export const EMPTY_HOTLES_ATTRIBUTES = "EMPTY_HOTLES_ATTRIBUTES";
