import React, { useEffect } from 'react'
import './Top_Destinations.css'
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from 'react-loading';
import { emptyBestLocation, loadBestLocation } from '../../../Redux/Actions/ActionHotel';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

function Top_Destinations({ locationRef }) {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    let dispatch = useDispatch();
    const bestLoc = useSelector(state => state.hotels.bestLoc)
    const loadingBestLoc = useSelector(state => state.hotels.loadingBestLoc)
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
        dispatch(emptyBestLocation())
        dispatch(loadBestLocation(langLocal))
    }, [langLocal])
    return (
        <div className='GeneralContainerSec' dir={langLocal == "ar" ? "rtl" : "ltr"} ref={locationRef} >
            <span className='header_title' >{t('topDest')}</span>
            <span className='header_desc' >{t('bestLocationSubTitle')}</span>
            <div className='GeneralRowSec' >
                {
                    loadingBestLoc == false ? (
                        bestLoc.map((dd) => {
                            return (
                                // <Link to="/hotels" >
                                <div key={dd.id} className='Destinations'>
                                    <Link
                                        className='Destinations_info'
                                        to="/hotels"
                                        onClick={() => localStorage.setItem('location', dd.id)}
                                    >
                                        <div>
                                            <span className='dest_name' >{dd.name}</span>
                                            <br></br>
                                            <span dir={langLocal == "ar" ? "rtl" : "ltr"}>{dd.hotels_count} {t('hotel')}</span>
                                        </div>
                                        
                                    </Link>
                                    <img src={dd.media_urls[0].url} />
                                </div>
                                // </Link>
                            )
                        })) : <div className='loadingg' > <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
                }
            </div>
            <div className='allItem'><button>{t('allDest')}</button></div>
        </div>
    )
}

export default Top_Destinations
