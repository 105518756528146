import React from 'react'
import { useTranslation } from 'react-i18next';

function Stars({num}) {
    const [t , i18n] = useTranslation()
    const star = [];
    for(var i=0 ; i<num ; i++){
        star[i]=i;
    }
  return (
    <div>
        {
            star.map((s , index)=>{
                return(
                    <i className="fa fa-star" key={index}></i> 
                )
            })
        }
        {
            star.length === 0 && (
                <span className='color' >{t('noRate')}</span>
            )
        }
    </div>
  )
}

export default Stars