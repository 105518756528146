import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <footer className="footer">
            <div className="GeneralContainer margin_bottom" >
                <div className='footer_row'>
                    <div className='one_footer' >
                        <h3>About Us</h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            <span>Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Corrupti Asperiores</span>
                        </div>
                    </div>
                    <div className='one_footer' >
                        <h3>Quick Link</h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            <span className='footer_link' >Home</span>
                            <span className='footer_link'>Hotels</span>
                            <span className='footer_link'>Restaurants</span>
                            <span className='footer_link'>Cars</span>
                        </div>
                    </div>
                    <div className='one_footer' >
                        <h3>Numbers </h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            <div className='num_footer' >
                                <i className='fa fa-phone' ></i>
                                <span>0933289353</span>
                            </div>
                            <div className='num_footer' >
                                <i className='fa fa-phone' ></i>
                                <span>0933289353</span>
                            </div>
                        </div>
                    </div>
                    <div className='one_footer' >
                        <h3>Settings</h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                        <div className='num_footer' >
                                <i className='fas fa-shield-alt' ></i>
                                <span>Terms and Privacy</span>
                            </div>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer