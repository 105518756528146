import * as types from "../ActionType";

const initState = {
    featuredHotels: [],
    allHotels: [],
    mediaHotel: [],
    loadingMediaHotel: true,
    mediaRoom: [],
    loadingMediaRoom: true,
    bestLoc: [],
    loadingBestLoc: true,
    rooms: [],
    hotelDetails: {},
    loadingFeaturedHotel: true,
    loadingAllHotels: true,
    loadingRooms: true,
    loadingHotelDetails: true,
    Reviews: [],
    loadingReviews: true,
    roomAtt: [],
    loadingRoomAtt: true,
    hotelAtt: [],
    loadingHotelAtt: true
}

const HotelsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_FEATURED_HOTEL:
            return { ...state, featuredHotels: action.payload, loadingFeaturedHotel: false }
        case types.EMPTY_FEATURED_HOTEL:
            return { ...state, featuredHotels: action.payload, loadingFeaturedHotel: true }
        case types.GET_ALL_HOTELS:
            return { ...state, allHotels: action.payload, loadingAllHotels: false }
        case types.EMPTY_ALL_HOTELS:
            return { ...state, allHotels: action.payload, loadingAllHotels: true }
        case types.ROOMS_BY_HOTEL:
            return { ...state, rooms: action.payload, loadingRooms: false }
        case types.EMPTY_ROOMS_HOTEL:
            return { ...state, rooms: action.payload, loadingRooms: true }
        case types.HOTER_DETAILS:
            return { ...state, hotelDetails: action.payload, loadingHotelDetails: false }
        case types.EMPTY_HOTER_DETAILS:
            return { ...state, hotelDetails: action.payload, loadingHotelDetails: true }
        case types.GET_MEDIA_HOTEL:
            return { ...state, mediaHotel: action.payload, loadingMediaHotel: false }
        case types.EMPTY_MEDIA_HOTEL:
            return { ...state, mediaHotel: action.payload, loadingMediaHotel: true }
        case types.GET_MEDIA_ROOM:
            return { ...state, mediaRoom: action.payload, loadingMediaRoom: false }
        case types.EMPTY_MEDIA_ROOM:
            return { ...state, mediaRoom: action.payload, loadingMediaRoom: true }
        case types.GET_BEST_LOCATION:
            return { ...state, bestLoc: action.payload, loadingBestLoc: false }
        case types.EMPTY_BEST_LOCATION:
            return { ...state, bestLoc: action.payload, loadingBestLoc: true }
        case types.GET_ALL_REVIEWS:
            return { ...state, Reviews: action.payload, loadingReviews: false }
        case types.EMPTY_ALL_REVIEWS:
            return { ...state, Reviews: action.payload, loadingReviews: true }
        case types.FILTER_HOTEL:
            return { ...state, allHotels: action.payload, loadingAllHotels: false }
        case types.FILTER_ROOM:
            return { ...state, rooms: action.payload, loadingRooms: false }
        case types.GET_ROOM_ATTRIBUTE:
            return { ...state, roomAtt: action.payload, loadingRoomAtt: false }
        case types.EMPTY_ROOM_ATTRIBUTE:
            return { ...state, roomAtt: action.payload, loadingRoomAtt: true }
        case types.GET_HOTLES_ATTRIBUTES:
            return { ...state, hotelAtt: action.payload, loadingHotelAtt: false }
        case types.EMPTY_HOTLES_ATTRIBUTES:
            return { ...state, hotelAtt: action.payload, loadingHotelAtt: true }
        default:
            return state;
    }
};

export default HotelsReducer;