import React, { useEffect } from 'react'
import NavBar from '../HomePage/NavBar/NavBar';
import headImage from '../../Images/header2.jpg'
import BackGround from '../General/BackGround';
import Application from '../HomePage/Footer/Application/Application';
import Footer from '../HomePage/Footer/Footer';
import SearchRestaurant from './SearchRestaurant';
import FilterHotel from '../AllHotels/FilterHotel';
import './AllRestaurant.css'
import OneRestaurant from './OneRestaurant';

function AllRestaurant() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <NavBar />
            <BackGround headImage={headImage} />
            <div className='GeneralContainer'>
                <SearchRestaurant />
                <br></br><br></br><br></br><br></br>
                <span className='header_title' >ALL RESTAURANTS</span>
                <span className='header_desc' >Find the right Restaurant for your needs</span>
                <div className='GeneralRow align' >
                    <div className='filter' >
                        <FilterHotel />
                    </div>
                    <div className='allRes' >
                        <OneRestaurant />
                    </div>
                </div>
            </div>
            <Application />
            <Footer />
        </div>

    )
}

export default AllRestaurant