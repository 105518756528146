import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { loadFilterRoom } from '../../Redux/Actions/ActionHotel';
import { useDispatch, useSelector } from "react-redux";

function FilterHotel({ id }) {
    let dispatch = useDispatch();
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    const [loadBtn, setloadBtn] = useState(false)
    const [children, setchildren] = useState('')
    const [adults, setadults] = useState('')
    const [min_price, setmin_price] = useState('')
    const [max_price, setmax_price] = useState('')
    const [active, setActive] = useState(true)
    const [active1, setActive1] = useState(true)
    const [active2, setActive2] = useState(true)
    const [active3, setActive3] = useState(true)
    const filterr = event => {
        event.preventDefault();
        dispatch(loadFilterRoom(id, children, adults, min_price, max_price, langLocal, setloadBtn))
    }
    return (
        <div className='filter'style={{width: "100%"}} >
            <div className='head_filter' >
                <i className='fa fa-sort' ></i>
                <h4>FILTER BY</h4>
            </div>
            <div className='one_filter' >
                <div className='one_filter_head' >
                    <span>{t('min_price')}</span>
                    <i className={active ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive(!active)} ></i>
                </div>
                {
                    active && (
                        <>
                            <input className='input_filter' type='number' onChange={(e) => setmin_price(e.target.value)} />
                        </>
                    )
                }

            </div>
            <div className='one_filter' >
                <div className='one_filter_head' >
                    <span>{t('max_price')}</span>
                    <i className={active1 ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive1(!active1)} ></i>
                </div>
                {
                    active1 && (
                        <>
                            <input className='input_filter' type='number' onChange={(e) => setmax_price(e.target.value)} />
                        </>
                    )
                }
            </div>
            <div className='one_filter' >
                <div className='one_filter_head' >
                    <span>{t('adults')}</span>
                    <i className={active3 ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive3(!active3)} ></i>
                </div>
                {
                    active3 && (
                        <input className='input_filter' type='number' onChange={(e) => setadults(e.target.value)} />
                    )
                }

            </div>
            <div className='one_filter' >
                <div className='one_filter_head' >
                    <span>{t('children')}</span>
                    <i className={active2 ? 'fas fa-angle-up' : 'fas fa-angle-down'} onClick={() => setActive2(!active2)} ></i>
                </div>
                {
                    active2 && (
                        <>
                            <input className='input_filter' type='number' onChange={(e) => setchildren(e.target.value)} />
                        </>
                    )
                }
            </div>
            <button onClick={filterr} >
                {
                    loadBtn ? "Loading ..." : "Filter"
                }
            </button>
        </div>
    )
}

export default FilterHotel