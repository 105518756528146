import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './Counter.css';
import { useDispatch, useSelector } from "react-redux";
import { loadStatic } from '../../../Redux/Actions/ActionStatic';

function Counter() {
    let dispatch = useDispatch();
    const Static = useSelector(state => state.staticCount.Static);
    const loadingStatic = useSelector(state => state.staticCount.loadingStatic);
    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    useEffect(() => {
        dispatch(loadStatic());
    }, [])

    useEffect(() => {
        if (inView && !loadingStatic) {
            const interval = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount === Static.best_locations_count) {
                        return prevCount;
                    } else {
                        return prevCount + 1;
                    }
                });
                setCount1((prevCount) => {
                    if (prevCount === Static.hotels_count) {
                        return prevCount;
                    } else {
                        return prevCount + 1;
                    }
                });
                setCount2((prevCount) => {
                    if (prevCount === Static.rooms_count) {
                        return prevCount;
                    } else {
                        return prevCount + 1;
                    }
                });
            }, 150);
            return () => {
                clearInterval(interval);
            };
        }
    }, [inView, loadingStatic, Static]);

    return (
        <div className='counter' ref={ref}>
            <div className='oneCounter'>
                <span className='numm'>{count1}</span>
                <span>Hotels</span>
            </div>
            <div className='oneCounter'>
                <span className='numm'>{count2}</span>
                <span>Rooms</span>
            </div>
            <div className='oneCounter'>
                <span className='numm'>{count}</span>
                <span>Locations</span>
            </div>
        </div>
    );
}

export default Counter;