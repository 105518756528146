import React, { useState, useEffect } from 'react'
import './TopBar.css'
import translate from '../../../../Images/worldWhite.png'
import useClickOutside from '../../../General/useClickOutside '
import { useTranslation } from "react-i18next";

function TopBar() {
  let langLocal = localStorage.getItem('langLocal');
  const [lang, setLang] = useState(false)
  const [t, i18n] = useTranslation();
  let domNode = useClickOutside(() => {
    setLang(false);
  });
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('langLocal'));
  }, [langLocal]);
  return (
    <div className='topBar' dir={langLocal == "ar" ? "rtl" : "ltr"}  >
      <div className='AlltopBar' >
        <div className='topBar_left' >
          <i className='fab fa-facebook-f' ></i>
          <i className='fab fa-instagram' ></i>
          <i className='fa fa-phone' ></i>
          <span className='ver_line' >|</span>
          <span>contact@bookingcore.test</span>
        </div>
        <div className='topBar_right' >
          {/*<div className='usd' >
            <span>USD</span>
            <i className='fa fa-arrow-circle-down' ></i>
  </div>*/}
          <div className='usd' onClick={() => setLang(!lang)} >
            <span>{t("lang")}</span>
            <img src={translate} />
          </div>

          {
            lang && (
              <div className={langLocal == "ar" ? 'popup_lang popup_lang_ar' : 'popup_lang popup_lang_en'} ref={domNode} dir={langLocal == "ar" ? "rtl" : "ltr"} >
                <span onClick={() => {
                  i18n.changeLanguage("ar");
                  console.log(i18n.language)
                  localStorage.setItem('langLocal', i18n.language);
                }} >{t('arabic')}</span>
                <span onClick={() => {
                  i18n.changeLanguage("en");
                  console.log(i18n.language)
                  localStorage.setItem('langLocal', i18n.language);
                }} >{t('english')}</span>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default TopBar