import React, { useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './Components/HomePage/Home';
import AllHotels from './Components/AllHotels/AllHotels';
import HotelDetails from './Components/HotelDetails/HotelDetails';
import AllRestaurant from './Components/AllRestaurant/AllRestaurant';
import DetailsRestaurant from './Components/DetailsRestaurant/DetailsRestaurant';
import LoadingLogOut from './Components/LoadingLogOut/LoadingLogOut';
import LoadingPage from './Components/General/LoadingPage';
import NotFound from './Components/PageNotFound/NotFound';


function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000)
  }, []);
  return (
    <div className='homee_app'>
      {
        isLoading ? (
          <LoadingPage />
        ) : (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/hotels" element={<AllHotels />} />
            <Route exact path="/hotel/:id" element={<HotelDetails />} />
            <Route exact path="/AllRestaurants" element={<AllRestaurant />} />
            <Route exact path="/RestaurantDetails" element={<DetailsRestaurant />} />
            <Route exact path="*" element={<NotFound />} />
            <Route exact path="/not-found" element={<NotFound />} />
          </Routes>
        )
      }

    </div>
  )
}

export default App;

