import React,{useEffect} from 'react'
import './Header.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactLoading from 'react-loading';
import noImg from '../../../Images/noImage2.png'
import { useTranslation } from "react-i18next";

function Header({ header, arr, loadingArr }) {
    let langLocal = localStorage.getItem('langLocal');
    const [t, i18n] = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langLocal'));
    }, [langLocal]);

    const settings = {
        autoPlay: true,
        infiniteLoop: true,
        showStatus: false,
        showThumbs: false
    }
    return (
        <>
            {
                loadingArr == false ? (
                    <Carousel {...settings} >
                        {
                            arr.map((image) => {
                                return (
                                    <div key={image.id} className={header ? 'header' : 'header lowHeight'} style={{ background: ' linear-gradient(rgba(53, 37, 37, 0.1), rgba(0, 0, 0, 0.1)), url(' + image.url + ') center/cover no-repeat' }} >
                                        <h2 className={langLocal == 'ar' ? "textAlignRight" : "textAlignLef"} > {t('titleHeader1')}</h2>
                                        <span className={langLocal == 'ar' ? "textAlignRight" : "textAlignLef"} >{t('titleHeader2')}</span>
                                    </div>
                                );
                            })
                        }
                    </Carousel>
                ) : <div className='header_loading' >  <ReactLoading type="cylon" color="#2AD2B6" height={'7%'} width={'7%'} /></div>
            }
            {
                loadingArr == false && arr.length == 0 ? (
                    <div className='header_loading' >
                        <img src={noImg} />
                    </div>
                ):<></>
            }
        </>
    )
}

export default Header